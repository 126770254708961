<template>
    <el-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        class="main-dialog"
        :title="$t('gdpr.title')"
        :visible.sync="needsToSignGDPR"
        :before-close="handleBeforeClose"
        @close="logOutUser"
    >
        <div class="description">{{ $t('gdpr.description') }}</div>
        <div class="gdpr-link cel-color">
            <a target="_blank" :href="gdprLink">{{ $t('gdpr.linkText') }}</a>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button icon="el-icon-check" type="primary" @click="confirmGDPR">{{ $t('gdpr.confirm') }}</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { sync, call, get } from 'vuex-pathify';
import { mapGetters } from 'vuex';
export default {
    data: () => ({
        gdprLink: 'https://www.cel.ro/index.php?main_page=prelucrare_date'
    }),
    methods: {
        LogOut: call('user/LogOut'),
        ApprovedGDPR: call('user/ApproveGDPR'),
        handleBeforeClose(done) {
            this.$confirm(this.$t('gdpr.confirm-exit'), this.$t('general.warning'), {
                confirmButtonText: this.$t('general.yes'),
                cancelButtonText: this.$t('general.no'),
                type: 'warning'
            })
                .then(() => {
                    // Log the fucker out and piss on him to show dominance
                    this.logOutUser();
                })
                .catch(() => {});
        },
        async logOutUser() {
            if (!this.needsToSignGDPR) return false;
            this.sbMsg({
                type: 'error',
                message: this.$t('gdpr.logout-message')
            });
            await this.LogOut();
            this.$router.push({ path: '/login' });
        },
        async confirmGDPR() {
            await this.ApprovedGDPR();
            this.sbMsg({
                type: 'success',
                message: this.$t('gdpr.confirm-message')
            });
        }
    },
    computed: {
        ...mapGetters({
            needsToSignGDPR: 'user/needsToSignGDPR'
        }),
        modalWidth() {
            return this.$store.state.app.device === 'mobile' ? '90%' : '35%';
        }
    }
};
</script>

<style scoped>
.main-dialog ::v-deep .el-dialog {
    width: 90%;
    max-width: 600px;
}

.main-dialog  ::v-deep .el-dialog__title {
    width: 95%;
    display: block;
    position: relative;
    word-break: break-word;
    font-size: 16px;
    font-weight: 700;
}

.description {
    line-height: 1.5;
    word-break: break-word;
    font-size: 14px;
}

.main-dialog  ::v-deep .el-dialog__body {
    padding: 20px;
}

.gdpr-link {
    margin-top: 10px;
}
</style>
